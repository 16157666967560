<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header" v-if="!filterInvoice">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <organization-selector
        v-if="
          !filterOrganization &&
          !filterInvoice &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => (selectedOrganization = organizationId)
        "
      />

      <base-input
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        :placeholder="`${$t('COMMON.LOCATIONS')}`"
      >
        <location-selector
          :showAll="true"
          @locationChanged="(locationId) => (selectedLocation = locationId)"
        />
      </base-input>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="purchasesPayments"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column label="Code" min-width="80px" prop="code" />

          <el-table-column
            :label="$t('COMMON.DATE')"
            prop="date"
            sortable="date"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ row.date | moment("LLLL") }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="220"
          >
            <template v-slot="{ row }">
              <purchasesPayment-status-badge :purchasesPayment="row" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.AMOUNT')"
            prop="amount"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatCurrency(row.amount) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.LOCATION')"
            sortable="custom"
            min-width="220"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <template v-slot="{ row }">
              <location :purchasesInvoice="row.invoice.id" />
            </template>
          </el-table-column>

          <el-table-column
            v-if="
              !filterOrganization &&
              !filterInvoice &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            :label="$t('COMMON.ORGANIZATION')"
            prop="organization"
            min-width="220"
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>

          <el-table-column
            v-if="!filterInvoice"
            :label="$t('COMMON.INVOICE')"
            prop="invoice.code"
            min-width="220"
          >
            <template v-slot="{ row }">
              <router-link :to="$objectViewRoute(row.invoice.id)">
                {{ row.invoice ? row.invoice.code : null }}
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.VEHICLE_CODE')"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <vehicle
                v-if="row.transaction"
                :vehicle="row.transaction.vehicle"
              />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('SIGNATURES.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="
                  $currentUserCan($permissions.PERM_VIEW_PURCHASES_PAYMENTS)
                "
              >
                <a
                  type="text"
                  @click="viewPurchasesPayment(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { INVOICE_STATUS_SENT } from "@/constants/invoices";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import Location from "@/components/Location";
import PurchasesPaymentStatusBadge from "./PurchasesPaymentStatusBadge.vue";
import Vehicle from "@/components/Vehicle";

export default {
  name: "purchasesPayment-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    PurchasesPaymentStatusBadge,
    LocationSelector,
    Location,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Vehicle,
  },

  mixins: [],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterInvoice: {
      type: String,
      default: null,
      description: "Invoice id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-date",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      purchasesPayments: [],
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      loading: true,
      selectedOrganization: null,
      selectedLocation: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterInvoice: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocation: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,invoice,allowedLocations,transaction.vehicle",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterInvoice) {
          params = {
            ...params,
            filter: { ...params.filter, invoice: this.filterInvoice },
          };
        }
        if (this.selectedLocation) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              search: this.selectedLocation,
            },
          };
        }

        await this.$store.dispatch("purchasesPayments/list", params);
        this.purchasesPayments = this.$store.getters["purchasesPayments/list"];
        this.total = this.$store.getters["purchasesPayments/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewPurchasesPayment(purchasesPayment) {
      this.$emit("onViewPurchasesPayment", purchasesPayment);
      /* this.$router.push({
        name: "View PurchasesPayment",
        params: { id: purchasesPayment.id },
      }); */
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>

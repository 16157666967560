<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="loading">
      <span class="loader"></span>
    </span>
    <div v-if="transaction" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <transaction-view-global
            :transaction="transaction"
            @onTransactionCanceled="transactionCanceled"
            @onTransactionBuyerChanged="transactionBuyerChanged"
            @onTransactionValidated="transactionValidated"
            @onEditTransaction="editTransaction"
            @onDeleteTransaction="deleteTransaction"
            @onReclamationCreated="reclamationCreated"
            :key="componentKey"
          />
        </tab-pane>
        <tab-pane title="global" id="2" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("TRANSACTIONS.SELLER_INVOICE") }}
          </span>
          <transaction-seller-invoice
            :transaction="transaction"
            :purchasesInvoice="purchasesInvoice"
          />
        </tab-pane>
        <tab-pane title="global" id="3" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("TRANSACTIONS.BUYER_INVOICE") }}
          </span>
          <transaction-buyer-invoice
            :transaction="transaction"
            :salesInvoice="salesInvoice"
            :key="componentKey"
          />
        </tab-pane>
        <tab-pane v-if="collecte" title="collecte" id="4" :active="true">
          <span slot="title">
            <i class="ni ni-money-coins"></i>
            {{ $t("COMMON.COLLECTE") }}
          </span>
          <collecte-view-global
            :collecte="collecte"
            @onValidateCollecte="validateCollecte"
            @onCollectCollecte="collectCollecte"
            @onDeliverCollecte="deliverCollecte"
            @onCancelCollecte="cancelCollecte"
          />
        </tab-pane>
        <tab-pane title="reclamation" id="5">
          <span slot="title">
            <i class="fab fa-accusoft"></i>
            {{ $t("COMMON.RECLAMATIONS") }}
          </span>
          <transaction-reclamation-list-table
            :filterTransaction="transaction?.id"
            :key="componentKey"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import TransactionViewGlobal from "../partials/TransactionViewGlobal.vue";
import TransactionSellerInvoice from "../partials/TransactionSellerInvoice.vue";
import TransactionBuyerInvoice from "../partials/TransactionBuyerInvoice.vue";
import CollecteViewGlobal from "../../CollecteManagement/partials/CollecteViewGlobal.vue";
import TransactionReclamationListTable from "../partials/TransactionReclamationListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    TransactionSellerInvoice,
    TransactionBuyerInvoice,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    TransactionViewGlobal,
    CollecteViewGlobal,
    TransactionReclamationListTable,
  },

  mixins: [requestErrorMixin],

  props: {
    transactionId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      transaction: null,
      salesInvoice: {},
      purchasesInvoice: {},
      collecte: null,
      loading: true,
      componentKey: 0,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        await this.$store.dispatch("transactions/get", this.transactionId);
        this.transaction = this.$store.getters["transactions/transaction"];
        this.invoices = await this.$store.dispatch(
          "transactions/getInvoices",
          this.transaction.id
        );
        await this.$store.dispatch(
          "purchasesInvoices/get",
          this.invoices.purchases_invoice_id
        );
        this.purchasesInvoice =
          this.$store.getters["purchasesInvoices/purchasesInvoice"];
        await this.$store.dispatch(
          "salesInvoices/get",
          this.invoices.sales_invoice_id
        );
        this.salesInvoice = this.$store.getters["salesInvoices/salesInvoice"];
        let params = {
          filter: {
            transaction: this.transactionId,
          },
        };
        await this.$store.dispatch("collectes/list", params);
        let collectes = this.$store.getters["collectes/list"];
        this.collecte = collectes[0];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    transactionCanceled(transaction) {
      this.$emit("onTransactionCanceled", transaction);
    },
    transactionBuyerChanged(transaction) {
      this.$emit("onTransactionBuyerChanged", transaction);
    },
    reclamationCreated() {
      this.componentKey += 1;
    },
    transactionValidated(transaction) {
      this.get();
      this.componentKey += 1;
    },
    validateCollecte(collecte) {
      this.$emit("onValidateCollecte", collecte);
    },
    collectCollecte(collecte) {
      this.$emit("onCollectCollecte", collecte);
    },
    deliverCollecte(collecte) {
      this.$emit("onDeliverCollecte", collecte);
    },
    cancelCollecte(collecte) {
      this.$emit("onCancelCollecte", collecte);
    },
    editTransaction(transaction) {
      this.$emit("onEditTransaction", transaction);
    },
    deleteTransaction(transaction) {
      this.$emit("onDeleteTransaction", transaction);
    },
  },
};
</script>
